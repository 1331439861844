// enum result: [:unknown, :success, :investigate, :late_start, :early_stop, :no_steps, :outside_geofence, :unusable, :fasttrack]
export default [
  { id: 'unknown', name: 'unknown' },
  { id: 'success', name: 'success' },
  { id: 'investigate', name: 'investigate' },
  { id: 'late_start', name: 'late_start' },
  { id: 'early_stop', name: 'early_stop' },
  { id: 'no_steps', name: 'no_steps' },
  { id: 'outside_geofence', name: 'outside_geofence' },
  { id: 'unusable', name: 'unusable' },
  { id: 'fasttrack', name: 'fasttrack' },
];

import React from 'react';
import { List, Datagrid, TextField, DateField, NumberField, ChipField, BooleanField, EditButton, TextInput, Filter, SelectInput, downloadCSV } from 'react-admin';
import './activities.scss';
import results from './results';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const ActivityFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="result" choices={results} disableValue="not_available" />
        <TextInput label="Airport" source="airport"  />
        <TextInput label="Flio track id" source="flio_track_id"  />
    </Filter>
);

const ResultField = ({ source, record = {} }) => <span className={"result-field result-field_" + record.result}>{record.result}</span>;

const exporter = (activities => {
    console.log(activities);
    const dataForExport = activities.map(activity => {
        // const { Act, swt } = activity; // omit backlinks and author
        activity.swt = JSON.stringify(activity.swt); // add a field
        return activity;
    });
    console.log(dataForExport);
    const csv = convertToCSV({
        data: dataForExport,
        fields: ['id', 'airport', 'result', 'swt'] // order fields in the export
    }, { delimiter: ';' });
    downloadCSV(csv, 'posts'); // download as 'posts.csv` file
});

export const ActivityList = props => (
    <List {...props} filters={<ActivityFilter />} exporter={exporter} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="airport" />
            <ResultField source="result" />
            <DateField source="updated_at" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ActivityList;

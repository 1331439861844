// in src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const token = btoa(username + ":" + password);
    localStorage.setItem('token', token);
    const request = new Request('admin/check_permission', {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': "Basic " + token }),
    })
    return fetch(request)
      .then(response => {
          if (response.status < 200 || response.status >= 300) {
              localStorage.removeItem('token');
              throw new Error(response.statusText);
          }
          return response.json();
      })
      .then(({ token }) => {
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    return Promise.resolve();
  }
  return Promise.resolve();
}

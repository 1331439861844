// in src/App.js
import React from 'react';
import {fetchUtils, Admin, Resource, Login } from 'react-admin';
import flowProvider from './flow_provider';
import activities from './activities';
import authProvider from './authProvider'

const App = () => (
    <Admin dataProvider={flowProvider} authProvider={authProvider}>
        <Resource name="activities" {...activities} />
    </Admin>
);

export default App;

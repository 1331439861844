import React from 'react';
import PropTypes from 'prop-types';

export const CustomTextField = ({ source, record = {} }) => (
    <div>
      <pre>
        <span>{ JSON.stringify(record[source], null, 2) }</span>
      </pre>
    </div>
);

CustomTextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default CustomTextField;

import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import { Link , Edit, SimpleForm, DisabledInput, TextInput, SelectInput } from 'react-admin';
import './activities.scss';
import results from './results'


const ActivityTitle = ({ record }) => {
    return <span>Activity: {record ? `"${record.id}"` : ''}</span>;
};

export const ActivityEdit = (props) => (
    <Edit title={<ActivityTitle />} {...props}>
        <SimpleForm>
            <DisabledInput label="Id" source="id" />
            <SelectInput source="result" choices={results} disableValue="not_available" />
        </SimpleForm>
    </Edit>
);

export default ActivityEdit;

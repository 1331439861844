import React from 'react';
import CardActions from '@material-ui/core/CardActions';
import { Show, SimpleShowLayout, Datagrid, TextField, DateField, ArrayField, NumberField, TabbedShowLayout, ChipField, BooleanField, Tab} from 'react-admin';
import { Link, EditButton } from 'react-admin';
import { CustomTextField } from '../custom_text_field';
import './activities.scss';

const ResultField = ({ source, record = {} }) => <span className={"result-field result-field_" + record.result}>{record.result}</span>;

const ActivityShowActions = ({ basePath, data, resource }) => (
    <CardActions>
        <Link 
          to={{ 
            pathname: '/activities'
          }}
        >Back</Link>
        <EditButton basePath={basePath} record={data} />
        <a href={`${resource}/${data.id}/visualize`} target="_blank">Visualize</a>
    </CardActions>
);

const ActivityTitle = ({ record }) => {
    return <span>Activity: {record ? `"${record.id}"` : ''}</span>;
};

export const ActivityShow = (props) => (
    <Show actions={<ActivityShowActions data={props} />} title={<ActivityTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Summary">
              <TextField source="id" />
              <TextField source="flio_track_id" />
              <TextField source="airport" />
              <TextField source="flow_type" />
              <CustomTextField source="meta" label="Meta info" />
              <TextField source="duration" />
              <BooleanField source="was_in_geofence" />
              <BooleanField source="has_steps" />
              <ResultField source="result" />
              <TextField source="swt" />
              <DateField label="Created at" source="created_at" showTime />
            </Tab>
            <Tab label="Additional info">
              <ArrayField source="relevant_intervals">
                <Datagrid>
                  <NumberField source="id" />
                  <NumberField source="distance_to_security" />
                  <NumberField source="activity" />
                  <NumberField source="duration" />
                  <NumberField source="step_distance" />
                  <NumberField source="heading" />
                </Datagrid>
              </ArrayField>
            </Tab>
            <Tab label="Points">
              <ArrayField source="points">
                <Datagrid>
                  <NumberField source="id" />
                  <DateField   source="timestamp" showTime/>
                  <NumberField source="lat" />
                  <NumberField source="lng" />
                  <NumberField source="step_distance" />
                  <NumberField source="duration" />
                </Datagrid>
              </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ActivityShow;
